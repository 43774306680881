<template>
  <div
    class="cart px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="Cart" size="large" />
    <div class="flex flex-wrap" v-if="!$store.getters['cart/isCartNotEmpty']">
      <div v-if="recentOrderId" class="w-full py-4">
        <h2>
          Recent order ID: <strong>{{ recentOrderId }}</strong>
        </h2>
      </div>
      <div class="w-full">
        <h2>
          Cart is currently empty, go to the
          <router-link style="color:#aa222c;" to="/shop">Shop</router-link>
        </h2>
      </div>
    </div>
    <div class="flex flex-wrap" v-if="$store.getters['cart/isCartNotEmpty']">
      <div class="w-full mb-6">
        <div>
          <table class="table-auto">
            <thead>
              <tr>
                <th class="px-4 py-2">Product</th>
                <th class="px-4 py-2">Price</th>
                <th class="px-4 py-2">Quantity</th>
                <th class="px-4 py-2">Subtotal</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, key) in $store.getters['cart/cart']" :key="key">
                <td class="border px-4 py-2 w-2/3">
                  <div class="text-center">
                    <img
                      class="h-24 m-auto"
                      :src="require(`@/assets/Shop/${item.product.Images[0]}`)"
                    />
                    <div style="color:#aa222c;">
                      {{ item.product.Name }}
                    </div>
                  </div>
                </td>
                <td class="border px-4 py-2">
                  {{ formatCurrency(item.product["Regular price"]) }}
                </td>
                <td class="border px-4 py-2">
                  <div class="inline-flex">
                    <button
                      @click="changeAmount('subtract', key)"
                      class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l focus:outline-none"
                    >
                      -
                    </button>
                    <input
                      disabled
                      :value="item.amount"
                      class="appearance-none border text-center text-gray-700 focus:outline-none w-10"
                      type="text"
                    />
                    <button
                      @click="changeAmount('add', key)"
                      class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r focus:outline-none"
                    >
                      +
                    </button>
                  </div>
                </td>
                <td class="border px-4 py-2">
                  {{
                    formatCurrency(item.product["Regular price"] * item.amount)
                  }}
                </td>
                <td class="border px-4 py-2">
                  <button
                    @click="removeItem(key)"
                    class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
                  >
                    <span>X</span>
                  </button>
                </td>
                <!-- <td class="border px-4 py-2">{{item}}</td> -->
              </tr>
            </tbody>
          </table>
        </div>
        <div class="text-left">
          <div class="p-6 mt-6 rounded overflow-hidden shadow-lg">
            <h2
              class="block uppercase tracking-wide text-gray-700 text-normal font-bold mt-8"
            >
              Shipping Info
            </h2>

            <div class="flex flex-wrap -mx-3 my-6">
              <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-first-name"
                >
                  First Name
                </label>
                <input
                  v-model="buyerDetails.first_name"
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-first-name"
                  type="text"
                  placeholder="First Name"
                />
              </div>
              <div class="w-full md:w-1/2 px-3">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-last-name"
                >
                  Last Name
                </label>
                <input
                  v-model="buyerDetails.last_name"
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-last-name"
                  type="text"
                  placeholder="Last Name"
                />
              </div>
            </div>

            <div class="flex flex-wrap -mx-3 my-6">
              <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-street-address"
                >
                  Street Address
                </label>
                <input
                  v-model="buyerDetails.street_address"
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-street-address"
                  type="text"
                  placeholder="Street Address"
                />
              </div>
              <div class="w-full md:w-1/2 px-3">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-apt"
                >
                  Apt., ste., bldg.
                </label>
                <input
                  v-model="buyerDetails.apt"
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-apt"
                  type="text"
                  placeholder="Apt., ste., bldg."
                />
              </div>
            </div>

            <div class="flex flex-wrap -mx-3 my-8">
              <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-city"
                >
                  City
                </label>
                <input
                  v-model="buyerDetails.city"
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-city"
                  type="text"
                  placeholder="City"
                />
              </div>
              <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-state"
                >
                  State
                </label>
                <div class="relative">
                  <select
                    @change="hasTax"
                    v-model="buyerDetails.state"
                    class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-state"
                  >
                    <option v-if="!paypalLoaded" value="">Select State</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <svg
                      class="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-zip"
                >
                  Zip
                </label>
                <input
                  v-model="buyerDetails.zip"
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-zip"
                  type="text"
                  placeholder="Zip"
                />
              </div>
            </div>
          </div>

          <div class="px-6 py-2 mt-6 rounded overflow-hidden shadow-lg">
            <div class="flex flex-wrap">
              <div class="w-full md:w-1/2">
                <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt
                    class="uppercase text-sm leading-5 font-medium text-gray-700"
                  >
                    Subtotal
                  </dt>
                  <dd
                    class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                  >
                    {{
                      formatCurrency($store.getters["cart/cartDetail"].subtotal)
                    }}
                  </dd>
                </div>
              </div>
              <div class="w-full md:w-1/2"></div>
              <div class="w-full md:w-1/2">
                <div
                  style="border-top:1px solid #d4d4d4;"
                  class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt
                    class="uppercase text-sm leading-5 font-medium text-gray-700"
                  >
                    Shipping
                  </dt>
                  <dd
                    class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                  >
                    {{
                      formatCurrency($store.getters["cart/cartDetail"].shipping)
                    }}
                  </dd>
                </div>
              </div>
              <div class="w-full md:w-1/2"></div>
              <div
                style="border-top:1px solid #d4d4d4;"
                v-if="$store.getters['cart/tax']"
                class="w-full md:w-1/2"
              >
                <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt
                    class="uppercase text-sm leading-5 font-medium text-gray-700"
                  >
                    Tax
                  </dt>
                  <dd
                    class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                  >
                    {{ formatCurrency($store.getters["cart/cartDetail"].tax) }}
                  </dd>
                </div>
              </div>
              <div
                v-if="$store.getters['cart/tax']"
                class="w-full md:w-1/2"
              ></div>
              <div class="w-full md:w-1/2">
                <div
                  style="border-top:2px solid #d4d4d4;"
                  class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt
                    class="uppercase text-sm leading-5 font-medium text-gray-700"
                  >
                    Total
                  </dt>
                  <dd
                    class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                  >
                    {{
                      formatCurrency($store.getters["cart/cartDetail"].total)
                    }}
                  </dd>
                </div>
              </div>
              <div class="w-full md:w-1/2"></div>
            </div>
          </div>

          <button
            v-if="!paypalLoaded"
            @click="proceed"
            class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 mt-6 rounded"
          >
            Proceed To Checkout
          </button>

          <div id="paypal-button-container" class="py-4"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";

export default {
  components: {
    TitleComponent
  },
  data: () => {
    return {
      recentOrderId: null,
      paypalLoaded: false,
      buyerDetails: {
        first_name: "",
        last_name: "",
        street: "",
        apt: "",
        city: "",
        state: "",
        zip: ""
      }
    };
  },
  beforeMount() {},
  methods: {
    formatCurrency(currency) {
      return currency.toLocaleString("en-US", {
        style: "currency",
        currency: "USD"
      });
    },
    proceed() {
      let vueThis = this;
      if (
        !this.buyerDetails.state ||
        !this.buyerDetails.city ||
        !this.buyerDetails.zip
      ) {
        alert("State, City, and Zipcode are required");
        return false;
      }
      var PAYPAL_SCRIPT =
        "https://www.paypal.com/sdk/js?client-id=AavPc9PEmyl9b6y7aWQwXWhOK2oNytnwW-fqykd401lO8pV-ywbS5auZeR29KSbwBhwfCcCaDTUFzU1Z";
      var script = document.createElement("script");
      script.setAttribute("src", PAYPAL_SCRIPT);
      document.head.appendChild(script);
      script.onload = () => {
        this.paypalLoaded = true;
        window.paypal
          .Buttons({
            style: {
              layout: "vertical",
              color: "gold",
              shape: "rect",
              label: "checkout"
            },
            createOrder: (data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      value: (
                        this.$store.getters["cart/cartDetail"].subtotal +
                        this.$store.getters["cart/cartDetail"].tax +
                        this.$store.getters["cart/cartDetail"].shipping
                      ).toFixed(2),
                      breakdown: {
                        item_total: {
                          currency_code: "USD",
                          value: this.$store.getters["cart/cartDetail"].subtotal
                        },
                        tax_total: {
                          currency_code: "USD",
                          value: this.$store.getters["cart/cartDetail"].tax
                        },
                        shipping: {
                          currency_code: "USD",
                          value: this.$store.getters["cart/cartDetail"].shipping
                        }
                      }
                    },
                    description: "SMS Purchase",
                    items: Object.keys(this.$store.getters["cart/cart"]).map(
                      key => {
                        let item = this.$store.getters["cart/cart"][key];

                        return {
                          name: item.product.Name,
                          unit_amount: {
                            currency_code: "USD",
                            value: item.product["Regular price"]
                          },
                          quantity: item.amount,
                          sku: item.product.SKU,
                          category: "PHYSICAL_GOODS"
                        };
                      }
                    ),
                    shipping: {
                      name: {
                        full_name:
                          this.buyerDetails.first_name +
                          " " +
                          this.buyerDetails.last_name
                      },
                      address: {
                        address_line_1: this.buyerDetails.street_address,
                        address_line_2: this.buyerDetails.apt,
                        admin_area_2: this.buyerDetails.city,
                        admin_area_1: this.buyerDetails.state,
                        postal_code: this.buyerDetails.zip,
                        country_code: "US"
                      }
                    }
                  }
                ]
              });
            },
            onApprove: function(data, actions) {
              return actions.order.capture().then(function(details) {
                vueThis.$store.commit("cart/setCart", {});
                vueThis.recentOrderId = details.id;
                fetch("/orderCompleted", {
                  method: "POST", // *GET, POST, PUT, DELETE, etc.
                  mode: "no-cors", // no-cors, *cors, same-origin
                  headers: {
                    "Content-Type": "application/json"
                  },
                  body: JSON.stringify(details) // body data type must match "Content-Type" header
                });
                alert(
                  "Transaction completed Order #: " + details.id
                  //"Transaction completed by " + details.payer.name.given_name
                );
              });
            }
          })
          .render("#paypal-button-container");
      };
    },
    removeItem(key) {
      this.$store.dispatch("cart/removeItem", key);
    },
    changeAmount(indicator, key) {
      let change;
      if (indicator === "add") {
        change = 1;
      } else {
        change = -1;
      }
      this.$store.dispatch("cart/updateCartAmount", { key, change });
    },
    hasTax() {
      if (
        event.currentTarget.value === "HI" ||
        event.currentTarget.value === "AK"
      ) {
        this.$store.commit("cart/setFarState", true);
      } else {
        this.$store.commit("cart/setFarState", false);
      }
      if (event.currentTarget.value === "UT") {
        this.$store.commit("cart/setTax", true);
      } else {
        this.$store.commit("cart/setTax", false);
      }
    }
  }
};
</script>

<style lang="scss">
.cart {
}
</style>
